<template>
  
    <div class="dao-header">
        <h2>DAO</h2>
        <div class="key-features-wrap">
            <div class="button-key">
                <p>COMMUNITY</p>
            </div>
        </div>
        <div class="accent-wrap"></div>
        <div class="accent-end">
            <img src="bg/barcode-accent.png" alt="">
        </div>
    </div>
    <div class="dao-main">
        <div class="accent-text-row">
            <p>DAO Components: Loading</p>
            <p>// Authenticating Credentials</p>
            <p>Rendering Status: 95%</p>
        </div>
        <div class="image-text-row-dao">

            <div class="text-wrapper-dao">
                <div class="text-top">
                    <div class="text-dao-accent"></div>
                    <p>fueled by an unparalleled fusion of state-of-the-art technologies. By harnessing the incredible power
                        of memes and artificial intelligence, this visionary network is on a mission to usher in an
                        unprecedented era of decentralized computing.</p>

                </div>
                <div class="text-bottom">
                    <div class="text-dao-accent"></div>
                    <p>The ROKO token, inspired by the legendary basilisk, embodies a powerful symbol of the immense
                        potential of advanced AI technology. The basilisk's mythological significance reminds us of the
                        incredible opportunities and challenges that
                        come with such transformative technology. The ROKO network has the power to bring about a remarkable
                        positive change, uplifting humanity and the world at large.</p>

                </div>

            </div>
            <div class="accent-dao">QUANTUM BANDWIDTH: <br>8.7 QBPS</div>

            <div class="image-wrapper-dao">
                <img src="bg/Dao-Image.png" alt="">
            </div>
        </div>
        <div class="treasury-data">
            <div class="treasury-data-text">
                <div class="treasury-data-boxes">
                    <div class="treasury-data-box">
                        <h2>1,034</h2>
                        <p>TAO</p>
                    </div>
                    <div class="treasury-data-box">
                        <h2>2,114M</h2>
                        <p>POND</p>
                    </div>
                    <div class="treasury-data-box">
                        <h2>299,435</h2>
                        <p>NOIA</p>
                    </div>
                    <div class="treasury-data-box">
                        <h2>176,17B</h2>
                        <p>ROKO</p>
                    </div>
                </div>
                <div class="treasury-desc">
                    <p>The Roko Network Treasury is managed by the DAO and it's funds are utilized to advance Roko Network's
                        deep infrastructure and further Roko's mission to bring forward cutting edge technology.</p>
                </div>
            </div>
            <img class="desktop" src="/bg/treasury.png" alt="">
            <img class="mobile" src="/bg/treasurymobile.png" alt="">
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'WhatIs',
};
</script>
  
<style lang="scss" scoped>
.mobile {
    display: none;
}

.treasury-data {
    padding: 2rem;
    position: relative;
    width: fit-content;

    img {
        width: 1400px;
        max-width: 100%;
    }

    .treasury-data-text {
        position: absolute;
        left: 50%;
        width: calc(40% - 4rem);
        margin-left: 4rem;
        height: 80%;
        display: flex;
        justify-content: space-evenly;
        flex-flow: column;

        &::before {
            content: 'Treasury Holdings';
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            color: white;
            left: 4rem;
            position: relative;
            top: 2rem;
            margin-bottom: 4rem;
            display: flex;
            flex-flow: column;
            justify-content: center;
            width: 180px;
            height: 32px;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
            background: black;
            margin-top: -10%;

        }

        .treasury-data-boxes {
            display: flex;
            flex-flow: wrap;
            width: auto;
            gap: 20%;

            .treasury-data-box {
                width: 30%;

                h2 {
                    font-size: 3rem;
                    font-weight: 400;
                    margin: unset;
                }

                p {
                    margin-top: 0rem;
                    margin-bottom: 0.5rem;
                }
            }
        }

        .treasury-desc {
            margin-top: 2rem;

            p {
                font-size: 0.75rem;
                width: 80%;
            }
        }
    }
}

.dao-main {
    position: relative;

    .accent-text-row {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        padding: 2rem;
        font-size: 8px;
        text-transform: uppercase;
    }

    .image-text-row-dao {
        padding: 2rem;
        display: flex;
        flex-flow: row;
        gap: 4rem;
        position: relative;

        .accent-dao {
            display: none;
            position: absolute;
            right: 400px;
            top: 200px;
            text-transform: uppercase;
            font-weight: 600;

            &::before {
                content: '';
                height: 1px;
                background: black;
                width: 11px;
                position: absolute;
                left: -20px;
                top: -11px;
            }

            &::after {
                content: '';
                width: 1px;
                background: black;
                height: 11px;
                position: absolute;
                left: -15px;
                top: -16px;
            }
        }

        .image-wrapper-dao {
            width: 40%;

            img {}
        }

        .text-wrapper-dao {
            display: flex;
            flex-flow: column;
            width: 50%;
            height: fit-content;
            position: relative;
            margin: auto;

            .text-top {
                height: fit-content;
                position: relative;
                width: calc(100% - 4rem);
                margin-left: 4rem;

                p {
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }

                .text-dao-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;

                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }

            .text-bottom {
                height: fit-content;
                position: relative;
                width: calc(100% - 4rem);
                margin-left: 4rem;

                p {
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }

                .text-dao-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;

                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }
        }
    }
}

.dao-header {
    border-bottom: 1px solid #C1C1C1;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0rem 2rem;
    margin-top:10rem;

    h2 {
        font-size: 70px;
        font-weight: 400;
        margin: unset;
    }

    .key-features-wrap {
        display: flex;
        flex-flow: column;
        padding: 2rem;
        justify-content: flex-end;

        .button-key {
            cursor: pointer;
            border: unset;
            width: min-content;
            padding: 0.5rem 1rem;
            font-family: "Rajdhani", sans-serif;
            background: black;
            color: white;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            font-weight: 600;
            gap: 10px;
            transition: all 0.2s ease;

            /* Space between text and icon */
            /* Add other button styles */
            &:hover {
                filter: invert(0.9);
            }

            p {
                margin: unset;
            }
        }
    }

    .accent-end {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 2rem;
    }
}

.info-field {
    height: 50vh;
    position: relative;
    display: flex;
    margin-left: 2rem;

    .info-block {
        position: absolute;
        right: 20%;
        top: 40%;

        &::before {
            content: '';
            position: absolute;
            right: -15px;
            top: -10px;
            height: 11px;
            width: 1px;
            background: black;
        }

        &::after {
            content: '';
            position: absolute;
            right: -20px;
            top: -5px;
            height: 1px;
            width: 11px;
            background: black;
        }
    }
}



@media (max-width: 1200px) {

    .mobile {
        display: flex;
    }

    .desktop {
        display: none;
    }

    .dao-header {
        border-bottom: 1px solid #C1C1C1;
        display: flex;
        flex-flow: row;
        margin-top: 5rem;
        justify-content: space-between;
        padding: 0rem 2rem;

        h2 {
            font-size: 10vw;
            font-weight: 400;
            margin: unset;
        }
    }

    .dao-main {

        .image-text-row-dao {
            flex-flow: column;

            .text-wrapper-dao {
                width: 100%;

                .text-top {
                    height: fit-content;
                    position: relative;
                    width: calc(100% - 0rem);
                    margin-left: 0rem;

                    p {
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 1.5;
                        margin: unset;
                        margin-bottom: 2rem;
                    }

                    .text-dao-accent {
                        position: absolute;
                        left: -4rem;
                        height: 100%;
                        width: 1px;
                        background: #C1C1C1;

                        &::after {
                            content: '';
                            height: 9px;
                            width: 9px;
                            left: -4px;
                            transform: rotate(45deg);
                            position: absolute;
                            background: #C1C1C1;
                            top: 0px;
                            transform-origin: center;
                        }
                    }
                }

                .text-bottom {
                    height: fit-content;
                    position: relative;
                    width: calc(100% - 0rem);
                    margin-left: 0rem;

                    p {
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 1.5;
                        margin: unset;
                        margin-bottom: 2rem;
                    }

                    .text-dao-accent {
                        position: absolute;
                        left: -4rem;
                        height: 100%;
                        width: 1px;
                        background: #C1C1C1;

                        &::after {
                            content: '';
                            height: 9px;
                            width: 9px;
                            left: -4px;
                            transform: rotate(45deg);
                            position: absolute;
                            background: #C1C1C1;
                            top: 0px;
                            transform-origin: center;
                        }
                    }
                }

            }

            .image-wrapper-dao {
                img {
                    display: none;
                }
            }
        }
    }

    .treasury-data {
        padding: 2rem;
        position: relative;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;


        img {
            width: 1400px;
            max-width: 120%;
            right: calc(-1rem + -10%);
            margin-left: unset;
            position: relative;
            z-index: -1;
        }

        .treasury-data-text {
            position: absolute;
            right: 10%;
            left: 0%;
            width: 90%;
            justify-content: center;
            margin-left: unset;

            &::before {
                content: 'Treasury Holdings';
                text-align: center;
                text-transform: uppercase;
                font-weight: 600;
                color: white;
                left: 15%;
                position: relative;
                top: 0rem;
                margin-bottom: 6rem;
                display: flex;
                flex-flow: column;
                justify-content: center;
                width: 140px;
                height: 24px;
                font-size: 0.75rem;
                clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
                background: black;

            }

            .treasury-data-boxes {
                display: flex;
                margin-left: 0%;
                flex-flow: wrap;
                justify-content: center;
                width: 100%;
                gap: 1rem;

                .treasury-data-box {
                    width: 40%;
                    display: flex;
                    flex-flow: column;

                    h2 {
                        font-size: 2rem;
                        font-weight: 400;
                        margin: auto;
                    }

                    p {
                        font-size: 1rem;
                        margin: auto;
                        margin-top: 0rem !important;
                    }
                }
            }

            .treasury-desc {
                margin-top: 2rem;
                display: none;

                p {
                    font-size: 0.75rem;
                    width: 80%;
                }
            }
        }
    }

    .dao-header {
        display: flex;
        flex-flow: column-reverse;

        .key-features-wrap {
            padding-left: unset !important;
            padding-bottom: 1rem !important;
        }
    }

    .accent-end {
        display: none !important;
    }

}</style>
  