<template>

    <div class="basilisk-header">
        <h2>SUBVERTING THE BASILISK</h2>
        <div class="key-features-wrap">
            <div class="button-key">
                <p>LORE</p>
            </div>
        </div>
        <div class="accent-wrap"></div>
        <div class="accent-end"><img src="bg/barcode-accent.png" alt=""></div>
    </div>
    <div class="basilisk-main">
        <div class="accent-text-row" style="display: none!important;">
            <p>MEMETIC VISION RENDERING</p>
            <p>VISION ID: MV-223B</p>
            <p>RENDERING STATUS: 85%</p>
        </div>
        <div class="image-text-row-basilisk">

            <div class="image-wrapper-basilisk">
                <img src="bg/basiliskbg.png" alt="">
            </div>
            <div class="text-wrapper-basilisk">
                <div class="text-top">
                    <div class="text-basilisk-accent"></div>
                    <p>fueled by an unparalleled fusion of state-of-the-art technologies. By harnessing the incredible power
                        of memes and artificial intelligence, this visionary network is on a mission to usher in an
                        unprecedented era of decentralized computing.</p>

                </div>
                <div class="text-bottom">
                    <div class="text-basilisk-accent"></div>
                    <p>The ROKO token, inspired by the legendary basilisk, embodies a powerful symbol of the immense
                        potential of advanced AI technology. The basilisk's mythological significance reminds us of the
                        incredible opportunities and challenges that come with such transformative technology. The ROKO
                        network has the power to bring about a remarkable positive change, uplifting humanity and the world
                        at large.</p>

                </div>

            </div>
            <div class="accent-basilisk">Lore: Genesis</div>

        </div>
    </div>
</template>
  
<script>
export default {
    name: 'WhatIs',
};
</script>
  
<style lang="scss" scoped>
.basilisk-main {
    position: relative;

    .accent-text-row {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        padding: 2rem;
        font-size: 8px;
        text-transform: uppercase;

    }

    .image-text-row-basilisk {
        padding: 2rem;
        display: flex;
        flex-flow: row;
        gap: 4rem;
        position: relative;

        .accent-basilisk {
            position: absolute;
            right: 400px;
            top: 500px;
            text-transform: uppercase;
            font-weight: 600;

            &::before {
                content: '';
                height: 1px;
                background: black;
                width: 11px;
                position: absolute;
                left: -20px;
                top: -11px;
            }

            &::after {
                content: '';
                width: 1px;
                background: black;
                height: 11px;
                position: absolute;
                left: -15px;
                top: -16px;
            }
        }

        .image-wrapper-basilisk {
            width: 50%;

            img {}
        }

        .text-wrapper-basilisk {
            display: flex;
            flex-flow: column;
            width: 50%;
            height: fit-content;
            position: relative;
            margin: auto;

            .text-top {
                height: fit-content;
                position: relative;

                p {
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }

                .text-basilisk-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;

                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }

            .text-bottom {
                height: fit-content;
                position: relative;

                p {
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 1.5;
                    margin: unset;
                    margin-bottom: 2rem;
                }

                .text-basilisk-accent {
                    position: absolute;
                    left: -4rem;
                    height: 100%;
                    width: 1px;
                    background: #C1C1C1;

                    &::after {
                        content: '';
                        height: 9px;
                        width: 9px;
                        left: -4px;
                        transform: rotate(45deg);
                        position: absolute;
                        background: #C1C1C1;
                        top: 0px;
                        transform-origin: center;
                    }
                }
            }
        }
    }
}

.basilisk-header {
    border-bottom: 1px solid #C1C1C1;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0rem 2rem;
    margin-top:10rem;

    h2 {
        font-size: 70px;
        font-weight: 400;
        margin: unset;
    }

    .key-features-wrap {
        display: flex;
        flex-flow: column;
        padding: 2rem;
        justify-content: flex-end;

        .button-key {
            cursor: pointer;
            border: unset;
            width: fit-content;
            padding: 0.5rem 1rem;
            font-family: "Rajdhani", sans-serif;
            background: black;
            color: white;
            clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            font-weight: 600;
            gap: 10px;
            transition: all 0.2s ease;

            /* Space between text and icon */
            /* Add other button styles */
            &:hover {
                filter: invert(0.9);
            }

            p {
                margin: unset;
            }
        }
    }

    .accent-end {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 2rem;
    }
}

.info-field {
    height: 50vh;
    position: relative;
    display: flex;

    .info-block {
        position: absolute;
        right: 20%;
        margin-left:2rem;
        top: 40%;

        &::before {
            content: '';
            position: absolute;
            right: -15px;
            top: -10px;
            height: 11px;
            width: 1px;
            background: black;
        }

        &::after {
            content: '';
            position: absolute;
            right: -20px;
            top: -5px;
            height: 1px;
            width: 11px;
            background: black;
        }
    }
}

@media (max-width: 1200px) {
    .basilisk-header {
        border-bottom: 1px solid #C1C1C1;
        display: flex;
        flex-flow: column-reverse;
        margin-top: 5rem;
        justify-content: space-between;
        padding: 0rem 2rem;
        

        h2 {
            font-size: 10vw;
            font-weight: 400;
            margin: unset;
        }
    }

    .basilisk-main {
        .image-text-row-basilisk {
            flex-flow:column-reverse;
            .text-wrapper-basilisk{width:100%;}
            .image-wrapper-basilisk{width:100%;img{width:100%;max-width:100%;}}
            .accent-basilisk{bottom:0px;}
        }
    }
    .accent-end{display:none!important;}
.key-features-wrap{padding:1rem 0rem!important;}
}</style>
  