<template>
    <div class="content">
        <div class="top-lines">
            <div class="left-text-box">Learning Rate: 0.023</div>
            <div class="right-text-box">Node ID: <br> 0x1A5F67E9
            </div>
        </div>
        <div class="text-content">
            <h1 class="text-title">ROKO <br>NETWORK</h1>
            <div class="text-area" v-html="displayedText"></div>
        </div>

        <div class="image-stack">
            <!-- Assume 'images' is an array of image URLs passed to this component -->
            <img v-for="(image, index) in images" :src="image" :key="index" :alt="'Image ' + index">
        </div>
        <div class="bottom-lines-row">
            <div class="bottom-text">
                <p>REALITY AUGMENTATION // SIMULATION EXPERIMENT</p>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        text: String,
        images: Array,
    },
    data() {
        return {
            rotationDegrees: [],
            rotationDirection: [],
            rotationSpeeds: [],
            displayedText: '', // Will store the current state of the typed text
        };
    },
    mounted() {
        this.applyInitialTransforms();
        this.addMouseMoveEffect();
        this.startContinuousRotation();
        this.typeText(); // Initiate typing effect when component mounts
    },
    methods: {
        applyInitialTransforms() {
            const stack = this.$el.querySelector('.image-stack');
            const images = stack.getElementsByTagName('img');
            for (let i = 0; i < images.length; i++) {
                images[i].style.transform = `translateZ(${i * 5}px)`;
                this.rotationDegrees[i] = 0;
                this.rotationDirection[i] = i % 2 === 0 ? 1 : -1;
                this.rotationSpeeds[i] = 0.05 + 0.05 * i;
            }
        },
        startContinuousRotation() {
            const updateRotation = () => {
                const images = this.$el.querySelector('.image-stack').getElementsByTagName('img');
                for (let i = 1; i < images.length; i++) {
                    this.rotationDegrees[i] += this.rotationDirection[i] * this.rotationSpeeds[i];
                    if (Math.abs(this.rotationDegrees[i]) > 10) {
                        this.rotationDirection[i] *= -1;
                    }
                    const currentTransform = images[i].style.transform;
                    const newTransform = currentTransform.replace(/rotateZ\(.*?\)/, '') + ` rotateZ(${this.rotationDegrees[i]}deg)`;
                    images[i].style.transform = newTransform;
                }
                requestAnimationFrame(updateRotation);
            };
            requestAnimationFrame(updateRotation);
        },
        addMouseMoveEffect() {
            const stack = this.$el.querySelector('.image-stack');
            stack.addEventListener('mousemove', e => {
                const { width, height, left, top } = stack.getBoundingClientRect();
                const x = e.clientX - left - width / 2;
                const y = e.clientY - top - height / 2;

                const images = stack.getElementsByTagName('img');
                for (let i = 0; i < images.length; i++) {
                    const depth = i * 5;
                    const xOffset = (x / width) * depth;
                    const yOffset = (y / height) * depth;
                    images[i].style.transform = `translate3d(${xOffset}px, ${yOffset}px, ${depth}px) rotateZ(${this.rotationDegrees[i]}deg)`;
                }
            });
        },
        typeText() {
            const text = this.text;
            let i = 0;
            const interval = 10; // Interval in milliseconds between characters

            const type = () => {
                if (i < text.length) {
                    this.displayedText += text.charAt(i);
                    i++;
                    setTimeout(type, interval);
                }
            };
            type();
        }
    }
}
</script>



  
<style scoped lang="scss">
@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
    }

    50% {
        transform: rotateZ(10deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes dropdown {
    0% {
        top: 0vh;
    }

    100% {
        top: 40vh;
    }
}

@keyframes dropdown-b {
    0% {
        height: 10vh;
    }

    100% {
        height: 50vh;
    }
}

@keyframes fadein {
    0% {
        filter: blur(100px);
        opacity: 0;
    }

    100% {
        filter: blur(0px);
        opacity: 1;
    }
}

.image-stack img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    transition: transform 0.5s ease-out;
    transform: translateZ(calc(var(--index) * -5px));
    /* Perspective effect */
    //animation: rotate 5s infinite ease-in-out; /* Apply continuous rotation */
}

.image-stack img:first-child {
    animation: none;
    transform: translateZ(-20px) !important;
    /* No rotation for the first image */
}

.image-stack img:nth-child(2) {
    mix-blend-mode: color-dodge;
    opacity: 0.5;
    filter: saturate(0);
    /* No rotation for the first image */
}

.image-stack {
    perspective: 600px;
    transform-style: preserve-3d;
    height: auto;
    position: relative;
    overflow: hidden;
    overflow: visible;
    animation: fadein 3s ease 1;
}

.bottom-lines-row {
    position: absolute;
    width: 100%;
    bottom: -8rem;
    height: 2rem;
    padding-bottom: 2rem;

    &::before {
        content: '';
        width: calc(20vw - 2rem - 6px);
        position: absolute;
        left: 0px;
        height: 1px;
        background: #C1C1C1;
    }

    &::after {
        content: '';
        width: calc(100% - 20vw - 450px - 6rem - 6px);
        position: absolute;
        right: 0px;
        height: 1px;
        background: #C1C1C1;
    }

    .bottom-text {
        position: absolute;
        border-bottom: 1px solid #C1C1C1;
        padding: 0rem 2rem;
        left: 20vw;
        width: 450px;

        p {
            margin-top: 0rem;
        }

        &::after {
            content: '';
            width: 3.4rem;
            height: 1px;
            background: #C1C1C1;
            position: absolute;
            right: 0px;
            bottom: 0px;
            transform-origin: right center;
            transform: rotate(135deg);
        }

        &::before {
            content: '';
            width: 3.4rem;
            height: 1px;
            background: #C1C1C1;
            position: absolute;
            left: 0px;
            bottom: 0px;
            transform-origin: left center;
            transform: rotate(-135deg);
        }
    }
}

.text-content {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    width: 40vw;
    padding: 2rem;
    margin-top: 40vh;
    max-width: 800px;

    .text-title {
        font-size: 10rem;
        font-weight: 400;
        margin: auto 0px;
        line-height: 10rem;
    }
}

.content {
    display: flex;
    align-items: center;
    height: auto;
    justify-content: space-between;
    position: relative;
    margin-top: 71px;
    margin-bottom: 5rem;

    &::after {
        content: '';
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0px;
        top: 40vh;
        animation: dropdown 2s ease 1;
        background-color: #C1C1C1;
        z-index: -1;
    }

    .top-lines {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        .left-text-box {
            position: absolute;
            left: 400px;
            top: 200px;
            text-transform: uppercase;
            font-weight: 600;

            &::before {
                content: '';
                height: 1px;
                background: black;
                width: 11px;
                position: absolute;
                left: -20px;
                top: -11px;
            }

            &::after {
                content: '';
                width: 1px;
                background: black;
                height: 11px;
                position: absolute;
                left: -15px;
                top: -16px;
            }
        }

        .right-text-box {
            position: absolute;
            right: 40px;
            top: 40px;
            text-align: right;
            text-transform: uppercase;
            font-weight: 600;
        }

        &::after {
            height: calc(110% + 4rem);
            top: -10%;
            right: 562px;
            content: '';
            width: 1px;
            background: #c1c1c1;
            position: absolute;
        }

        &::before {
            height: 50vh;
            animation: dropdown-b 2s ease 1;
            top: -10vh;
            left: 201px;
            content: '';
            width: 1px;
            background: #c1c1c1;
            position: absolute;
        }
    }
}

.text-area {
    flex: 1;
    min-height:200px;
}

.image-stack {
    flex: 1;
    margin: 0rem;
    top:-5vw;
    right:0px;
    width: 40vw;
    height: 40vw;
    max-width: 800px;
    max-height: 800px;
    position: relative;

    img {
        position: absolute;
        height: inherit;
        width: inherit;
    max-width: 1200px;
    max-height: 1200px;
    }
}

.image-stack img {
    position: absolute;
    top: 0;
    right: 0;
    /* Apply necessary styles to stack the images as needed */
}

@media screen and (max-width:1600px) {



    .bottom-lines-row {
        position: absolute;
        width: 100%;
        bottom: -8rem;
        height: 2rem;
        padding-bottom: 2rem;

        &::before {
            content: '';
            width: calc(20vw - 2rem - 6px) !important;
            position: absolute;
            left: 0px;
            height: 1px;
            background: #C1C1C1;
        }

        &::after {
            content: '';
            width: calc(100% - 20vw - 450px - 0rem - 6px) !important;
            position: absolute;
            right: 0px;
            height: 1px;
            background: #C1C1C1;
        }

        .bottom-text {
            position: absolute;
            border-bottom: 1px solid #C1C1C1;
            padding: 0rem 2rem;
            left: 20vw;
            bottom: 1.5rem;
            width: 355px;

            p {
                margin-top: 0rem;
                font-size: calc(2vw * 0.5);
            }

            &::after {
                content: '';
                width: 3.4rem;
                height: 1px;
                background: #C1C1C1;
                position: absolute;
                right: 0px;
                bottom: 0px;
                transform-origin: right center;
                transform: rotate(135deg);
            }

            &::before {
                content: '';
                width: 3.4rem;
                height: 1px;
                background: #C1C1C1;
                position: absolute;
                left: 0px;
                bottom: 0px;
                transform-origin: left center;
                transform: rotate(-135deg);
            }
        }
    }

    .top-lines {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        .left-text-box {
            position: absolute;
            left: 400px;
            top: 200px;
            text-transform: uppercase;
            font-weight: 600;

            &::before {
                content: '';
                height: 1px;
                background: black;
                width: 11px;
                position: absolute;
                left: -20px;
                top: -11px;
            }

            &::after {
                content: '';
                width: 1px;
                background: black;
                height: 11px;
                position: absolute;
                left: -15px;
                top: -16px;
            }
        }

        .right-text-box {
            position: absolute;
            right: 40px;
            top: 40px;
            text-align: right;
            text-transform: uppercase;
            font-weight: 600;
        }

        &::after {
            height: calc(110% + 4rem);
            top: -10%;
            right: 35% !important;
            content: '';
            width: 1px;
            background: #c1c1c1;
            position: absolute;
        }

        &::before {
            height: 50vh;
            animation: dropdown-b 2s ease 1;
            top: -10vh;
            left: 201px;
            content: '';
            width: 1px;
            background: #c1c1c1;
            position: absolute;
        }
    }
}

@media (max-width: 1200px) {
    .text-content {
        width: 100%;
        margin-top: 0vw !important;
        margin-bottom: 80vw;
        z-index: 10;
        line-height: 1.2;
        max-width: unset;

        .text-title {
            font-size: 20vw;
            font-weight: 400;
            margin: auto 0px;
            margin-bottom: 2rem;
            line-height: 18vw;
        }
    }

    .image-stack {
        flex: 1;
        margin: 0rem;
        width: 100vw;
        height: 100vw;
        top: calc(40vw + 12rem);
        position: absolute;
        margin-left: -1rem;

        img {
            position: absolute;
            height: inherit;
            width: inherit;
        }
    }

    .top-lines {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        .left-text-box {
            position: absolute;
            left: unset !important;
            right: 5vw;
            font-size: calc(1.5vw + 0.25rem);
            top: 8vw !important;
            text-transform: uppercase;
            font-weight: 600;

            &::before {
                content: '';
                height: 1px;
                background: black;
                width: 2vw !important;
                position: absolute;
                left: -2vw !important;
                top: -1vw !important;
            }

            &::after {
                content: '';
                width: 1px;
                background: black;
                height: 2vw !important;
                position: absolute;
                left: -1vw !important;
                top: -2vw !important;
            }
        }

        .right-text-box {
            display: none;
            position: absolute;
            right: 40px;
            top: 40px;
            text-align: right;
            text-transform: uppercase;
            font-weight: 600;
        }

        &::after {
            height: calc(110% + 4rem);
            top: -10%;
            right: 562px;
            content: '';
            width: 1px;
            background: #c1c1c1;
            position: absolute;
            display: none !important;
        }

        &::before {
            height: 50vh;
            animation: dropdown-b 2s ease 1;
            top: -10vh;
            left: 201px;
            content: '';
            width: 1px;
            background: #c1c1c1;
            position: absolute;
            display: none !important;
        }
    }

    .text-area {
        flex: 1;
        font-size: calc(1.5vw + 0.5rem);
    }

    .bottom-lines-row {
        position: absolute;
        width: 200%;
        bottom: -8rem;
        height: 2rem;
        padding-bottom: 2rem;
        transform: scale(0.5);
        transform-origin: left;

        &::before {
            content: '';
            width: calc(20vw - 2rem - 6px);
            position: absolute;
            left: 0px;
            height: 1px;
            background: #C1C1C1;
        }

        &::after {
            content: '';
            width: calc(100% - 40vw - 459px - 0rem - 6px) !important;
            
            position: absolute;
            right: 0px;
            height: 1px;
            background: #C1C1C1;
        }

        .bottom-text {
            position: absolute;
            border-bottom: 1px solid #C1C1C1;
            padding: 0rem 2rem;
            left: 20vw;
            width: 450px;

            p {
                font-size: 1rem;
                margin-top: 0rem;
            }

            &::after {
                content: '';
                width: 3.4rem;
                height: 1px;
                background: #C1C1C1;
                position: absolute;
                right: 0px;
                bottom: 0px;
                transform-origin: right center;
                transform: rotate(135deg);
            }

            &::before {
                content: '';
                width: 3.4rem;
                height: 1px;
                background: #C1C1C1;
                position: absolute;
                left: 0px;
                bottom: 0px;
                transform-origin: left center;
                transform: rotate(-135deg);
            }
        }
    }

    .content {
        &::after {
            top: calc(100px + 65vw);
            display: none;
        }
    }
}</style>
  