<template>
    <div class="wrapper-info">
        <header-component></header-component>
        <hero id="hero" :text="text" :images="images"></hero>
        <!--<h1 style="font-weight:100;padding:1rem;text-align:center;opacity:0.5;margin:20vh auto ;">SOMETHING IS COMING</h1>-->
        <!-- The rest of the parent component's template would go here -->
        <what-is id="whatis"></what-is>
        <prototypes id="prototypes"></prototypes>
        <infrastructure id="infrastructure"> </infrastructure>
        <tokenomics id="tokenomics"> </tokenomics>
        <dao id="dao" > </dao>
        <basilisk id="basilisk"> </basilisk>
        <Footer id="footer"></Footer>
    </div>
</template>
  
<script>
// Import the header component from its file location
import HeaderComponent from '../components/home-components/Header.vue';
import Hero from '../components/home-components/Hero.vue';
import WhatIs from '../components/home-components/WhatIs.vue';
import Prototypes from '../components/home-components/Prototypes.vue';
import Infrastructure from '../components/home-components/Infrastructure.vue';
import Tokenomics from '../components/home-components/Tokenomics.vue';
import Dao from '../components/home-components/Dao.vue';
import Basilisk from '../components/home-components/Basilisk.vue';
import Footer from '../components/home-components/Footer.vue';

export default {
    name: 'ParentComponent',
    components: {
        // Register the HeaderComponent for use in the template
        Hero,
        WhatIs,
        Prototypes,
        HeaderComponent,
        Infrastructure,
        Tokenomics,
        Dao,
        Basilisk,
        Footer,
    },
    data() {
        return {
            text: 'Imagine a future where virtual realities are not just art, but a tangible reality that can be accessed through an index. Picture a system where a series of possible virtual realities are presented, and both humans and machines work together towards a common goal. This is the future of our world, where we can prompt possible realities like we do in mid-journey, and actively take steps towards realizing them.',
            images: [
                //'/bg/Union.svg',
                '/bg/layer1.png',
                '/bg/layer2.png',
                '/bg/layer3.png',
                '/bg/layer4.png',
                '/bg/layer5.png',
                '/bg/layer6.png',
                '/bg/layer7.png',
                '/bg/layer8.png',

            ]
        }
    }

};
</script>
  
<style lang="scss">
body {
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-color: white;
}

.wrapper-info {
    margin: auto;
    width: 1800px;
    max-width: calc(100% - 8rem);
    position: relative;
    overflow-y: visible;
    overflow-x:hidden;
    &::after {
        content: '';
        border: 1px solid #C1C1C1;
        border-top: unset;
        border-bottom: unset;
        width: calc(100% - 2px);
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
    }
    &::before {
        content: '';
        width: 100vw;
        height: 100vh;
        top: 0px;
        position: fixed;
        left: 0px;
        backdrop-filter:blur(15px)brightness(1.2);z-index: -1;
    }
}
@media screen and (max-width:1200px) {


    .wrapper-info {
    margin: auto;
    width: 1800px;
    max-width: calc(100% - 2rem - 2px);
    position: relative;
    overflow-y: auto;    overflow: hidden;
    &::after {
        content: '';
        border: 1px solid #C1C1C1;
        border-top: unset;
        border-bottom: unset;
        width: calc(100% - 2px);
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
    }
    &::before {
        content: '';
        width: 100vw;
        height: 100vh;
        top: 0px;
        position: fixed;
        left: 0px;
        backdrop-filter:blur(15px)brightness(1.2);z-index: -1;
    }
}    
}
/* Parent component styles here */
</style>
  
