<template>
    <header class="header">
        <div class="header-logo">
            <!-- Replace with actual logo image -->
            <a href="#hero"><img src="favicon-roko.png" alt="ROKO NETWORK">
                <div class="header-text">ROKO NETWORK</div>
            </a>
        </div>

        <div class="header-model-id">
            <p>MODEL ID: <br>ROKO-ML-573</p>
        </div>
        <div class="nav-area">

            <nav class="header-navigation">
                <ul>
                    <li><a href="#prototypes">PROTOTYPES</a></li>
                    <li><a href="#infrastructure">INFRA</a></li>
                    <li><a href="#basilisk">LORE</a></li>
                    <li><a href="#dao">DOCS</a></li>

                </ul>
            </nav>
            <div class="button-wrapper">
                <button class="header-try-roko">
                    TRY ROKO
                    <!-- Replace with actual icon image or use a font icon library -->
                    <img src="plustry.svg" alt="Try Roko">
                </button>
            </div>
        </div>
    </header>
</template>
  
<script>
export default {
    name: 'HeaderComponent',
};
</script>
  
<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    border-bottom: 1px solid #C1C1C1;
    background: #fff5;
    width: 1800px;
    max-width: calc(100vw - 8rem);
    margin: auto;
    position: fixed;
    z-index: 100;
    backdrop-filter: blur(10px);
    /* Add other styles such as height, box-shadows, etc */
}

.header-logo {
    display: flex;
    flex-flow: row;
    height: 70px;
    min-width: 200px;
    border-left: 1px solid #C1C1C1;

    a {
        margin-top: auto;
        display: flex;
        flex-flow: row;
        text-decoration: none;

        .header-text {
            font-weight: 400!important;
            letter-spacing: 1px;
            margin-top: auto;
            margin-bottom: 16px;
            margin-left: 8px;
            text-decoration: none;color:black!important;
        }

        img {
            margin-top: auto;
            margin-bottom: 10px;
            margin-left: 10px;
            height: 32px;
        }
    }

    /* Adjust width and height accordingly */
}

.header-model-id {
    border-left: 1px solid #C1C1C1;
    display: flex;
    height: 70px;
    flex-flow: row;
    justify-content: flex-start;
    width: 100%;

    p {
        font-weight: 400;
        font-size: 8px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-top: auto;
    }

    /* Style for the model ID */
}

.nav-area {
    display: flex;
    border-right: 1px solid #C1C1C1;
    flex-flow: row;

    .button-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: center;
        height: 70px;
        width: 200px;
        border-left: 1px solid #C1C1C1;

        button {
            margin-bottom: 10px;
            margin-top: auto;
        }
    }
}

.header-navigation {
    border-left: 1px solid #C1C1C1;
    height: 70px;
    display: flex;
    flex-flow: column;
    width: 360px;

    ul {
        padding: 0px 40px;
        margin: auto;
        margin-bottom: 18px;
        list-style: none;
        display: flex;
        gap: 20px;

        li {
            width: fit-content;

            a {
                cursor: pointer;
                font-size: 12px;
                color: black;
                text-decoration: none;
            }
        }
    }

    /* Adjust as needed for separation */
}

.header-navigation li::after {
    content: '/';
    padding-left: 20px;
}

.header-navigation li:last-child::after {
    content: '';
    padding-left: unset;
}

.header-try-roko {
    cursor: pointer;
    border: unset;
    width: 120px;
    padding: 0.5rem 1rem;
    font-family: "Rajdhani", sans-serif;
    background: black;
    color: white;
    clip-path: polygon(0.5rem 0%, 100% 0, 100% calc(100% - 0.5rem), calc(100% - 0.5rem) 100%, 0 100%, 0 0.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    font-weight: 600;
    gap: 10px;
    transition: all 0.2s ease;

    /* Space between text and icon */
    /* Add other button styles */
    &:hover {
        filter: invert(0.9);
    }
}

.header-try-roko img {
    margin-top: -2px;
    /* Adjust width and height for the icon */
}

@media (max-width: 1200px) {
    .header-model-id {
        display: none;
    }

    .header-navigation {
        display: none;
    }

    .nav-area {
        display: flex;
        border-right: 1px solid #C1C1C1;
        flex-flow: row;

        .button-wrapper {
            display: flex;
            flex-flow: row;
            justify-content: center;
            height: 70px;
            width: auto;
            padding: 0rem 1rem;
            border-left: 1px solid #C1C1C1;

            button {
                margin-bottom: 10px;
                margin-top: auto;
            }
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        border-bottom: 1px solid #C1C1C1;
        background: #fff5;
        width: 1800px;
        max-width: calc(100vw - 2rem - 2px);
        margin: auto;
        position: fixed;
        z-index: 100;
        backdrop-filter: blur(10px);
        /* Add other styles such as height, box-shadows, etc */
    }

}</style>
  
